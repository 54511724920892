import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Markdown from "react-markdown";

import replaceIcons from "../functions/global";

const BlocksStreamers = ({alt, myStyle, title, description}) => {
	const { allStrapiStreamer, site } = useStaticQuery(query);

	description = replaceIcons(description);

	var styleClassName = 'style1';

	switch (myStyle) {
		case 1: styleClassName = 'style1';
			break;
		case 2: styleClassName = 'style2';
			break;
		case 3: styleClassName = 'style3';
			break;
		case 4: styleClassName = 'style4';
			break;
		default: styleClassName = 'style1';
			break;
	}

	return (
		<section id="one" className={alt ? "wrapper spotlight alt "+styleClassName+" text-left" : "wrapper spotlight "+styleClassName+" text-left"}>
			<div className="inner d-block">
				<h3>{title}</h3>
				<Markdown source={description} escapeHtml={false} />
				<div className="row">
					{allStrapiStreamer.edges.map((astreamer, i) => (
						<div className="col-12 col-md-6 mb-3" key={"item-"+i}>
							<h4 className="major">
								{(() => {

									astreamer.node.description = replaceIcons(astreamer.node.description);

									switch (astreamer.node.platform) {
										case 'Youtube':
											return (<a href={"https://www.youtube.com/watch?v="+astreamer.node.channelId}>{astreamer.node.name} <i className="fab fa-youtube-square"></i></a>);
										default:
											return (<a href={"https://www.twitch.tv/"+astreamer.node.channelId}>{astreamer.node.name} <i className="fab fa-twitch"></i></a>);
									}
								})()}
							</h4>
							{(() => {
								switch (astreamer.node.platform) {
									case 'Youtube':
										return (<iframe width="100%" height="300" src={"https://www.youtube.com/embed/"+astreamer.node.videoId} title={astreamer.node.name+"-video"} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="allowfullscreen"></iframe>);
									default:
										var tmpParent = site.siteMetadata.parentUrl;
										tmpParent = tmpParent.replace('https://','');
										return (<iframe src={"https://player.twitch.tv/?channel="+astreamer.node.videoId+"&parent="+tmpParent} title={astreamer.node.name+"-video"} frameBorder="0" allowFullScreen="allowfullscreen" scrolling="no" height="300" width="100%"></iframe>);
								}
							})()}
							<div className="streamer-text">
								{astreamer.node.description !== null ?
									<Markdown source={astreamer.node.description} escapeHtml={false} />
								: '' }
								{astreamer.node.socialmedias.length ?
									<div className="d-flex justify-content-between social-player mb-3 me-2">
										<h5 className="mr-2">Follow {astreamer.node.name} on:</h5>
										<div>
										{astreamer.node.socialmedias.map((socialmediaicon, i) => (
											<a href={socialmediaicon.url} key={"item-"+i} target="_blank" rel="noreferrer">
												{(() => {
													switch (socialmediaicon.platform) {
														case 'Twitter':
															return (<i className="fab fa-twitter-square"></i>);
														case 'Facebook':
															return (<i className="fab fa-facebook-square"></i>);
														case 'Instagram':
															return (<i className="fab fa-instagram-square"></i>);
														case 'Discord':
															return (<i className="fab fa-discord"></i>);
														case 'Youtube':
															return (<i className="fab fa-youtube-square"></i>);
														default:
															return (<i className="fas fa-external-link-square-alt"></i>);
													}
												})()}
											</a>
										))}
										</div>
									</div>
								:
									''
								}
							</div>
						</div>
					))}
				</div>
			</div>
		</section>
	);
};

export default BlocksStreamers;

const query = graphql`
	query {
		allStrapiStreamer(sort: {fields: sort, order: ASC}) {
			edges {
				node {
					name
					channelId
					videoId
					sort
					description
					platform
					socialmedias {
						name
						platform
						url
					}
				}
			}
		}
		site {
			siteMetadata {
				imagePrefix
				parentUrl
			}
		}
	}
`;
