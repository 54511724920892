import React from "react";
import Markdown from "react-markdown";
import { useStaticQuery, graphql } from "gatsby";

import replaceIcons from "../functions/global";

class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			api: 'lsajkdh921SAD-8uASDj2',
			subject: 'noceisawesome',
			name: '',
			email: '',
			category: '',
			copy: false,
			message: '',
			url: ''
		}
	}

	render() {
		return(
			<section>
				<h3 className="major">{this.props.formTitle}</h3>
				<form id="contact" method="POST" onSubmit={this.handleSubmit.bind(this)}>
					<div className="row gtr-uniform">
						<input hidden type="text" name="subject" id="subject" value={this.state.subject} onChange={this.onSubjectChange.bind(this)}/>
						<div className="col-6 col-12-xsmall">
							<label htmlFor="name">Name</label>
							<input type="text" name="name" id="name" required value={this.state.name} onChange={this.onNameChange.bind(this)}/>
						</div>
						<div className="col-6 col-12-xsmall">
							<label htmlFor="email">Email</label>
							<input type="email" name="email" id="email" required value={this.state.email} onChange={this.onEmailChange.bind(this)}/>
						</div>
						{(() => {
							if (this.props.categories.length) {
								return (
									<div className="col-12">
										<label htmlFor="category">Category</label>
										<select name="category" id="category" value={this.state.category} onChange={this.onCategoryChange.bind(this)}>
											<option selected={this.state.category === '' ? true : false} value="">-</option>
											{this.props.categories.map((categorie, i) => (
												<option key={categorie.title} selected={this.state.category === categorie.title ? true : false} value={categorie.emailTo}>{categorie.title}</option>
											))}
										</select>
									</div>
								);
							}
						})()}
						<div className="col-6 col-12-small">
							<input type="checkbox" id="copy" name="copy" checked={this.state.copy} onChange={this.onCopyChange.bind(this)}/>
							<label htmlFor="copy">Email me a copy</label>
						</div>
						<div className="col-12">
							<label htmlFor="message">Message</label>
							<textarea name="message" id="message" required rows="6" value={this.state.message} onChange={this.onMessageChange.bind(this)} />
						</div>
						<div className="col-12 d-flex justify-content-between">
							<div className="actions">
								<button type="submit" id="contact-submit" className="primary">Send Message</button>
							</div>
							<div id="contact-alert" className="alert alert-danger py-1 px-3 d-flex align-items-center visually-hidden" role="alert">

							</div>
						</div>
					</div>
				</form>
			</section>
		);
	}

	onSubjectChange(event) {
		this.setState({subject: event.target.value})
	}
	onNameChange(event) {
		this.setState({name: event.target.value})
	}
	onEmailChange(event) {
		this.setState({email: event.target.value})
	}
	onCategoryChange(event) {
		this.setState({category: event.target.value})
	}
	onCopyChange(event) {
		this.setState({copy: !this.state.copy})
	}
	onMessageChange(event) {
		this.setState({message: event.target.value})
	}
	handleSubmit(event) {
		event.preventDefault();
		document.getElementById("contact-submit").innerHTML = "Loading...";
		fetch(this.props.url+'/mail/send', {
			method: "POST",
			body: JSON.stringify(this.state),
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
		}).then(
			(response) => (response.json())
		).then((response)=> {
			if (response.status === 'success') {
				document.getElementById("contact").innerHTML = "<h4>Thank you for contacting us! We will get in touch with you as soon as possible.</h4>";
			} else if(response.status === 'fail') {
				document.getElementById("contact-alert").innerHTML = "An error occured while sending, try again or contact us per email.";
				document.getElementById("contact-alert").classList.toggle("visually-hidden");
				document.getElementById("contact-submit").innerHTML = "Send Message";
			}
		})
	}
}

const Contactform = ({alt, style, text, text2, formTitle, categories, socialmedia}) => {
	const { strapiGlobal, site } = useStaticQuery(query);

	text = replaceIcons(text);
	text2 = replaceIcons(text2);

	return (
		<section id="four" className="wrapper alt style1">
			<div className="inner">
				{text ? <Markdown source={text} escapeHtml={false} /> : ''}
				{socialmedia ? <h3 className="title">{strapiGlobal.footerTitleLeft}</h3> : ''}
				{(() => {
					if (socialmedia) {
						return (
							<div className="d-flex justify-content-left social-player mb-5">
								{strapiGlobal.socialmedia.map((socialmediaicon, i) => (
									<a href={socialmediaicon.url} key={socialmediaicon.platform} target="_blank" rel="noreferrer">
										{(() => {
											switch (socialmediaicon.platform) {
												case 'Twitter':
													return (<i className="fab fa-twitter-square"></i>);
												case 'Facebook':
													return (<i className="fab fa-facebook-square"></i>);
												case 'Instagram':
													return (<i className="fab fa-instagram-square"></i>);
												case 'Discord':
													return (<i className="fab fa-discord"></i>);
												case 'Youtube':
													return (<i className="fab fa-youtube-square"></i>);
												default:
													return (<i className="fas fa-external-link-square-alt"></i>);
											}
										})()}
									</a>
								))}
							</div>
						);
					}
				})()}

				<App formTitle={formTitle} categories={categories} url={site.siteMetadata.parentUrl}/>
				{text2 ? <Markdown source={text2} escapeHtml={false} /> : ''}
			</div>
		</section>
	);
};

export default Contactform;

const query = graphql`
	query {
		strapiGlobal {
			footerTitleLeft
			socialmedia {
				platform
				url
			}
		}
		site {
			siteMetadata {
				imagePrefix
				parentUrl
			}
		}
	}
`;
