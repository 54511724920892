import React from "react";
import Markdown from "react-markdown";
import Moment from "react-moment";

import replaceIcons from "../functions/global";

const Text = ({alt, myStyle, back, text, more, urltext, url}) => {

	if (text !== null) {
		text = replaceIcons(text);
	}

	return (
		<section id="four" className={alt ? "wrapper alt style1" : "wrapper style1"}>
			<div className="inner">
				{back != null && back.on ? <a href={back.link} id="back-button" class="special back">{back.text}</a> : ''}
				{back != null && back.on ? <h2 className="major">{back.title}</h2> : ''}
				{back != null && back.on ? <h3><Moment format="DD.MM.YYYY HH:mm">{back.published_at}</Moment></h3> : ''}
				<Markdown source={text} escapeHtml={false} />
				{(() => {
					if (more) {
						if (url.startsWith('http')) {
							return (<a href={url} target="_blank" rel="noreferrer" className={text != null && text !== '' ? "special" : "special text-center"}>{urltext}</a>);
						} else {
							return (<a href={url} className={text != null && text !== '' ? "special" : "special text-center"}>{urltext}</a>);
						}
					}
				})()}
			</div>
		</section>
	);
};

export default Text;
