import React from "react";
import Markdown from "react-markdown";
import Moment from "react-moment";
import { useStaticQuery, graphql } from "gatsby";

import replaceIcons from "../functions/global";

const Image = ({alt, myStyle, back, text, more, urltext, url, person, image}) => {
	const { site } = useStaticQuery(query);

	if (text !== null) {
		text = replaceIcons(text);
	}

	var styleClassName = 'style1';
	switch (myStyle) {
		case 1: styleClassName = 'style1';
			break;
		case 2: styleClassName = 'style2';
			break;
		case 3: styleClassName = 'style3';
			break;
		case 4: styleClassName = 'style4';
			break;
		default: styleClassName = 'style1';
			break;
	}

	if (!person) {
		styleClassName = styleClassName + ' news';
	}

	return (
		<section id="one" className={alt ? "wrapper spotlight alt " + styleClassName : "wrapper spotlight " + styleClassName}>
			{(() => {
				if (back != null && back.on) {
					return (
						<div class="inner" style={{ flexDirection: 'column', alignItems: 'normal', }}>
							<a href={back.link} id="back-button" class="special back">{back.text}</a>
							<h2 className="major">{back.title}</h2>
							<h3><Moment format="DD.MM.YYYY HH:mm">{back.published_at}</Moment></h3>
						</div>
					);
				} else {
					return '';
				}
			})()}
			<div className="inner">
				{image != null ?
					<div className="image"><img src={site.siteMetadata.imagePrefix + image.url} alt={image.alternativeText} /></div>
				:
					''
				}
				<div className="content">
					<Markdown source={text} escapeHtml={false} />
					{(() => {
						if (more) {
							if (url.startsWith('http')) {
								return (<a href={url} target="_blank" rel="noreferrer" className="special">{urltext}</a>);
							} else {
								return (<a href={url} className="special">{urltext}</a>);
							}
						}
					})()}
				</div>
			</div>
		</section>
	);
};

export default Image;

const query = graphql`
	query {
		site {
			siteMetadata {
				imagePrefix
			}
		}
	}
`;
