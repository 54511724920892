import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Markdown from "react-markdown";

import replaceIcons from "../functions/global";

const BlocksTeams = ({alt, myStyle}) => {
	const { site, allStrapiTeam, allStrapiPlayer } = useStaticQuery(query);

	var styleClassName = 'style1';
	var isFirst = true;

	return (
		<div className="teamsWrapper">
			{allStrapiTeam.edges.map((ateam, i) => {
				alt ? alt = false : alt = true;
				myStyle >= 4 ? myStyle = 1 : myStyle += 1;

				ateam.node.shortDescription = replaceIcons(ateam.node.shortDescription);

				switch (myStyle) {
					case 1: styleClassName = 'style1';
						break;
					case 2: styleClassName = 'style2';
						break;
					case 3: styleClassName = 'style3';
						break;
					case 4: styleClassName = 'style4';
						break;
					default: styleClassName = 'style1';
						break;
				}
				if (alt) {
					styleClassName = "alt " + styleClassName
				}
				if (!isFirst) {
					styleClassName = "spotlight " + styleClassName;
				}
				styleClassName = "wrapper " + styleClassName + " text-left";;
				return (
					<section id={isFirst ? "four" : "one"} className={styleClassName}>
						<div className={isFirst ? "inner" : "inner d-block"}>
							{isFirst ? <h2 className="major">Our Teams</h2> : "" }
							{isFirst ? isFirst = false : ''}
							<img className="title" src={site.siteMetadata.imagePrefix + ateam.node.game.logo.url} alt={ateam.node.game.logo.alternativeText}/>
							<h3>{ateam.node.title && ateam.node.title !== '' ? ateam.node.title : ateam.node.game.name}</h3>
							{ateam.node.shortDescription !== null ?
								<Markdown source={ateam.node.shortDescription} escapeHtml={false} />
							: ''}
							<section className="features clear-both pt-2">
								{allStrapiPlayer.nodes.map((aplayer, i) => {
									if (aplayer.team !== null){ if(aplayer.team.slug === ateam.node.slug && aplayer.aktive) {
										if (aplayer.shortDescription !== null) {
											aplayer.shortDescription = aplayer.shortDescription.replace('[#i-','<i class="');
											aplayer.shortDescription = aplayer.shortDescription.replace('-i#]','"></i> ');
										}
										return (
											<article>
												{ateam.node.showAvatarsInListing && aplayer.person.avatar && aplayer.person.avatar.url && aplayer.person.avatar.url !== '' ?
													<img className="" src={site.siteMetadata.imagePrefix + aplayer.person.avatar.url} alt={aplayer.person.avatar.alternativeText}/>
												:
													''
												}
												<h3 className="major">{aplayer.person.firstname +" '"+ aplayer.gamertag +"' "+ aplayer.person.lastname}</h3>
												<h4 className="mb-2">Role: {aplayer.role.name}</h4>
												{aplayer.shortDescription !== null ?
													<Markdown source={aplayer.shortDescription} escapeHtml={false} />
												: ''}
												{aplayer.person.socialmedias.length ?
													<h5>Follow {aplayer.gamertag} on:</h5>
												: ''}
												{aplayer.person.socialmedias.length ?
													<div className="d-flex justify-content-center social-player mb-3">
														{aplayer.person.socialmedias.map((socialmediaicon, i) => (
															<a href={socialmediaicon.url} target="_blank" rel="noreferrer">
																{(() => {
																	switch (socialmediaicon.platform) {
																		case 'Twitter':
																			return (<i className="fab fa-twitter-square"></i>);
																		case 'Facebook':
																			return (<i className="fab fa-facebook-square"></i>);
																		case 'Instagram':
																			return (<i className="fab fa-instagram-square"></i>);
																		case 'Discord':
																			return (<i className="fab fa-discord"></i>);
																		case 'Youtube':
																			return (<i className="fab fa-youtube-square"></i>);
																		case 'Twitch':
																			return (<i className="fab fa-twitch"></i>);
																		default:
																			return (<i className="fas fa-external-link-square-alt"></i>);
																	}
																})()}
															</a>
														))}
													</div>
												:
													''
												}
											</article>
										);
									} else {return '';}
								} else {return '';}
								})}
								<article className="d-flex align-items-center align-self-baseline">
									<a href={'/teams/'+ateam.node.slug} className="special mb-2">more info about the team</a>
								</article>
							</section>
						</div>
					</section>
				);
			})}
		</div>
	);
};

export default BlocksTeams;

const query = graphql`
	query {
		site {
			siteMetadata {
				imagePrefix
			}
		}
		allStrapiTeam(sort: {fields: sort, order: ASC}) {
			edges {
				node {
					slug
					sort
					shortDescription
					title
					game {
						name
						short
						logo {
							alternativeText
							caption
							url
						}
					}
					showAvatarsInListing
				}
			}
			totalCount
		}
		allStrapiPlayer(sort: {fields: role___sort, order: ASC}) {
			nodes {
				aktive
				gamertag
				longDescription
				person {
					birthdate
					avatar {
						alternativeText
						caption
						url
					}
					firstname
					lastname
					socialmedias {
						name
						platform
						url
					}
				}
				role {
					name
					game
					sort
				}
				shortDescription
				videos {
					platform
					title
					videoid
				}
				team {
					slug
				}
			}
		}
	}
`;
