import React from "react";
import Markdown from "react-markdown";
import Moment from "react-moment";
import { useStaticQuery, graphql } from "gatsby";

import replaceIcons from "../functions/global";

const Gallery = ({alt, myStyle, back, text, text2, images}) => {
	const { site } = useStaticQuery(query);

	if (text !== null) {
		text = replaceIcons(text);
	}
	if (text2 !== null) {
		text2 = replaceIcons(text2);
	}

	return (
		<section id="four" className={alt ? "wrapper alt style1" : "wrapper style2"}>
			<div className="inner">
				{back != null && back.on ? <a href={back.link} id="back-button" class="special back">{back.text}</a> : ''}
				{back != null && back.on ? <h2 className="major">{back.title}</h2> : ''}
				{back != null && back.on ? <h3><Moment format="DD.MM.YYYY HH:mm">{back.published_at}</Moment></h3> : ''}
				{text ? <Markdown source={text} escapeHtml={false} /> : ''}
				{images.length ?
					<section className="row gtr-uniform images">
						{images.map((animage, i) => (
							<div className="d-flex align-items-center col-4 mb-5">
								<span className="image fit">
									<img src={site.siteMetadata.imagePrefix + animage.image.url} alt={animage.image.alternativeText}/>
								</span>
							</div>
						))}
					</section>
				:
					''
				}
				{text2 ? <Markdown source={text2} escapeHtml={false} /> : ''}
			</div>
		</section>
	);
};

export default Gallery;

const query = graphql`
	query {
		site {
			siteMetadata {
				imagePrefix
			}
		}
	}
`;
