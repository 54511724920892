import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";

import Banner from "../components/banner";

import BlocksText from "../components/blocks/text";
import BlocksFeature from "../components/blocks/feature";
import BlocksImage from "../components/blocks/image";
import BlocksGallery from "../components/blocks/gallery";
import BlocksNews from "../components/blocks/news";
import BlocksContactform from "../components/blocks/contactform";
import BlocksTeams from "../components/blocks/teams";
import BlocksStreamers from "../components/blocks/streamers";

import Recruiting from "../components/recruiting";

export const query = graphql`
	query PageQuery($slug: String!) {
		strapiPage(slug: { eq: $slug}){
			title
			slug
			seo {
				metaTitle
				metaDescription
				shareImage {
					url
				}
			}
			banner {
				title
				description
			}
			sections {
				name
				title
				description
				text
				text2
				more
				socialmedia
				formTitle
				url
				urltext
				person
				features {
					name
					title
					description
					more
					urltext
					url
				}
				image {
					url
					caption
					alternativeText
				}
				images {
					name
					caption
					image {
						url
						caption
						alternativeText
					}
				}
				limit
				categories {
					title
					emailTo
				}
			}
			recruiting
		}
		allStrapiTeam {
			totalCount
		}
		allStrapiNew {
			totalCount
		}
	}
`;

const Page = ({ data }) => {
	const page = data.strapiPage;
	const newsCount = data.allStrapiNew.totalCount;
	const teamsCount = data.allStrapiTeam.totalCount;

	var sectionIsAlt = false;
	var sectionStyle = 0;
	var helperIsAlt = sectionIsAlt;
	var helperStyle = sectionStyle;
	var n = 0;

	return (
		<Layout page={page.slug} seo={page.seo}>
			<Banner banner={page.banner}/>

			<section id="wrapper">
				{page.sections.map((section, i) => {
					switch (section.name) {
						case 'blocksText':
							sectionIsAlt ? sectionIsAlt = false : sectionIsAlt = true;
							return (<BlocksText alt={sectionIsAlt} myStyle={sectionStyle} key={section.name+"-"+i}
								text={section.text}
								more={section.more ? section.more : ""}
								urltext={section.urltext ? section.urltext : ""}
								url={section.url ? section.url : ""}/>);
						case 'blocksFeature':
							if (section.text || section.features.length) {
								sectionIsAlt ? sectionIsAlt = false : sectionIsAlt = true;
								return (<BlocksFeature alt={sectionIsAlt} myStyle={sectionStyle} key={section.name+"-"+i}
									text={section.text ? section.text : ""}
									text2={section.text2 ? section.text2 : ""}
									more={section.more ? section.more : ""}
									urltext={section.urltext ? section.urltext : ""}
									url={section.url ? section.url : ""}
									features={section.features}/>);
							} else {return '';}
						case 'blocksImage':
							if (section.text) {
								sectionIsAlt ? sectionIsAlt = false : sectionIsAlt = true;
								sectionStyle >= 4 ? sectionStyle = 1 : sectionStyle += 1;
								return (<BlocksImage alt={sectionIsAlt} myStyle={sectionStyle} key={section.name+"-"+i}
									text={section.text}
									more={section.more ? section.more : ""}
									urltext={section.urltext ? section.urltext : ""}
									url={section.url ? section.url : ""}
									person={section.person}
									image={section.image}/>);
							} else {return '';}
						case 'blocksGallery':
							if (section.images.length) {
								sectionIsAlt ? sectionIsAlt = false : sectionIsAlt = true;
								return (<BlocksGallery alt={sectionIsAlt} myStyle={sectionStyle} key={section.name+"-"+i}
									text={section.text ? section.text : ""}
									text2={section.text2 ? section.text2 : ""}
									images={section.images}/>);
							} else {return '';}
						case 'blocksNews':
							if (section.limit != null) {
								helperIsAlt = sectionIsAlt;
								helperStyle = sectionStyle;
								n = newsCount;
								if (section.limit > 0) { n = section.limit; }
								for (i = 0; i < n; i++){
									sectionIsAlt ? sectionIsAlt = false : sectionIsAlt = true;
									sectionStyle >= 4 ? sectionStyle = 1 : sectionStyle += 1;
								}
								return (<BlocksNews alt={helperIsAlt} myStyle={helperStyle} key={section.name+"-"+i}
									limit={section.limit} home={false}/>);
							} else {return '';}
						case 'blocksContactform':
							sectionIsAlt ? sectionIsAlt = false : sectionIsAlt = true;
							return (<BlocksContactform alt={sectionIsAlt} myStyle={sectionStyle} key={section.name+"-"+i}
								text={section.text ? section.text : ""}
								text2={section.text2 ? section.text2 : ""}
								formTitle={section.formTitle}
								categories={section.categories}
								socialmedia={section.socialmedia}/>);
						case 'blocksTeams':
							helperIsAlt = sectionIsAlt;
							helperStyle = sectionStyle;
							n = teamsCount;
							for (i = 0; i < n; i++){
								sectionIsAlt ? sectionIsAlt = false : sectionIsAlt = true;
								sectionStyle >= 4 ? sectionStyle = 1 : sectionStyle += 1;
							}
							return (<BlocksTeams alt={helperIsAlt} myStyle={helperStyle} key={section.name+"-"+i}
								/>);
						case 'blocksStreamers':
							sectionIsAlt ? sectionIsAlt = false : sectionIsAlt = true;
							sectionStyle >= 4 ? sectionStyle = 1 : sectionStyle += 1;
							return (<BlocksStreamers alt={sectionIsAlt} myStyle={sectionStyle} key={section.name+"-"+i}
								title={section.title ? section.title : ""}
								description={section.description ? section.description : ""}
								/>);
						default: return('');
					}
				})}

				{(() => {
					if (page.recruiting) {
						sectionIsAlt ? sectionIsAlt = false : sectionIsAlt = true;
						return (
							<Recruiting alt={sectionIsAlt}/>
						);
					}
					return ('');
				})()}
			</section>
		</Layout>
	);
};

export default Page;
