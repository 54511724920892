import React from "react";
import { useStaticQuery, graphql } from "gatsby"
import Markdown from "react-markdown";

import replaceIcons from "./functions/global";

const Recruiting = ({alt}) => {
	const { strapiGlobal, site } = useStaticQuery(query);

	if (strapiGlobal.defaultRecruiting !== null) {
		strapiGlobal.defaultRecruiting.text = replaceIcons(strapiGlobal.defaultRecruiting.text);

		return (
			<section id="four" className={alt ? "wrapper alt style1" : "wrapper style1"}>
				<div className="inner">
					<h2 className="major">{strapiGlobal.defaultRecruiting.title}</h2>
					<Markdown source={strapiGlobal.defaultRecruiting.text} escapeHtml={false} />
					<section className="features">
						{strapiGlobal.defaultRecruiting.position.map((aposition, i) => {
							if (aposition.description !== null) { aposition.description = replaceIcons(aposition.description);}
							return (
							<article>
								<h3 className="d-flex justify-content-between major">
									{aposition.title}
									{aposition.titleIcon != null ?
										<img className="title-small" src={site.siteMetadata.imagePrefix + aposition.titleIcon.url} alt={aposition.titleIcon.alternativeText}/>
									:
										''
									}
								</h3>
								{aposition.description !== null ?<Markdown source={aposition.description} escapeHtml={false} />:''}
								{aposition.more ?
									aposition.url.startsWith('http') ?
										<a href={aposition.url} target="_blank" rel="noreferrer" className="special">{aposition.urltext}</a>
									:
										<a href={aposition.url} className="special">{aposition.urltext}</a>
								:
									''
								}
							</article>
							);
						})}
						<article className="d-flex align-items-center">
							<a href="/contact" className="special">join us</a>
						</article>
					</section>
					<Markdown source={strapiGlobal.defaultRecruiting.text2} escapeHtml={false} />
				</div>
			</section>
		);
	} else {
		return '';
	}
};

export default Recruiting;

const query = graphql`
	query {
		strapiGlobal {
			siteName
			defaultRecruiting {
				title
				text
				position {
					name
					title
					titleIcon {
						url
						alternativeText
						caption
					}
					description
					more
					urltext
					url
				}
				text2
			}
		}
		site {
			siteMetadata {
				imagePrefix
			}
		}
	}
`;
