import React from "react";
import Markdown from "react-markdown";
import Moment from "react-moment";
import { useStaticQuery, graphql } from "gatsby";

import replaceIcons from "../functions/global";

const Feature = ({alt, myStyle, back, text, text2, more, urltext, url, features}) => {
	const { site } = useStaticQuery(query);

	if (text !== null) {
		text = replaceIcons(text);
	}
	if (text2 !== null) {
		text2 = replaceIcons(text2);
	}

	return (
		<section id="four" className={alt ? "wrapper alt style1" : "wrapper style2"}>
			<div className="inner">
				{back != null && back.on ? <a href={back.link} id="back-button" class="special back">{back.text}</a> : ''}
				{back != null && back.on ? <h2 className="major">{back.title}</h2> : ''}
				{back != null && back.on ? <h3><Moment format="DD.MM.YYYY HH:mm">{back.published_at}</Moment></h3> : ''}
				{text ? <Markdown source={text} escapeHtml={false} /> : ''}
				{features.length ?
					<section className="features">
						{features.map((feature, i) => {
							if (feature.description !== null) { feature.description = replaceIcons(feature.description);}
							return (
							<article>
								<h3 className="d-flex justify-content-between major">
									{feature.title}
									{feature.titleIcon != null ?
										<img className="title-small" src={site.siteMetadata.imagePrefix + feature.titleIcon.url} alt={feature.titleIcon.alternativeText}/>
									:
										''
									}
								</h3>
								{feature.description !== null ?<Markdown source={feature.description} escapeHtml={false} />:''}
								{feature.more ?
									feature.url.startsWith('http') ?
										<a href={feature.url} target="_blank" rel="noreferrer" className="special">{feature.urltext}</a>
									:
										<a href={feature.url} className="special">{feature.urltext}</a>
								:
									''
								}
							</article>
							);
						})}
					</section>
				:
					''
				}
				{text2 ? <Markdown source={text2} escapeHtml={false} /> : ''}
				{more ?
					url.startsWith('http') ?
						<a href={url} target="_blank" rel="noreferrer" className="special">{urltext}</a>
					:
						<a href={url} className="special">{urltext}</a>
				:
					''
				}
			</div>
		</section>
	);
};

export default Feature;

const query = graphql`
	query {
		site {
			siteMetadata {
				imagePrefix
			}
		}
	}
`;
