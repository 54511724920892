import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Moment from "react-moment";

const BlocksNews = ({alt, myStyle, limit, home}) => {
	const { site, allStrapiNew } = useStaticQuery(query);

	var styleClassName = 'style1';

	return (
		<div className="newsWrapper">
			{allStrapiNew.edges.map((anews, i) => {
				if (i < limit || limit === 0) {
					alt ? alt = false : alt = true;
					myStyle >= 4 ? myStyle = 1 : myStyle += 1;
					switch (myStyle) {
						case 1: styleClassName = 'style1';
							break;
						case 2: styleClassName = 'style2';
							break;
						case 3: styleClassName = 'style3';
							break;
						case 4: styleClassName = 'style4';
							break;
						default: styleClassName = 'style1';
							break;
					}
					return (
						<section id="one" className={alt ? "wrapper spotlight alt news " + styleClassName : "wrapper spotlight news " + styleClassName}>
							<div className="inner">
								{anews.node.image != null ?
									<div className="image"><img src={site.siteMetadata.imagePrefix + anews.node.image.image.url} alt={anews.node.image.image.alternativeText} /></div>
								:
									''
								}
								<div className="content">
									<h2 className="major">{anews.node.title}</h2>
									<h3><Moment format="DD.MM.YYYY HH:mm">{anews.node.published_at}</Moment></h3>
									<p>{anews.node.description}</p>
									{ anews.node.more ?
										<a href={"/news/"+anews.node.slug} className="special">Read more</a>
									:
										''
									}
								</div>
							</div>
						</section>
					);
				} else {return '';}
			})}
			{allStrapiNew.totalCount <= limit && home ? home = false : ''}
			{home ?
				<section id="four" className={alt ? "wrapper style2" : "wrapper alt style2"}>
					<div className="inner text-center">
						<a href="/news" className="special">all news</a>
					</div>
				</section>
			:
				''
			}
		</div>
	);
};

export default BlocksNews;

const query = graphql`
	query {
		site {
			siteMetadata {
				imagePrefix
			}
		}
		allStrapiNew(sort: {fields: published_at, order: DESC}) {
			edges {
				node {
					slug
					published_at
					more
					description
					image {
						caption
						name
						image {
							alternativeText
							caption
							url
						}
					}
					title
				}
			}
			totalCount
		}
	}
`;
